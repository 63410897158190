import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";

function LeftTab() {
  const [pathname, setPathName] = useState([]);
  useEffect(() => {
    var path = window.location.pathname;
    path = path.split("/");
    setPathName(path, () => {});
  }, []);

  return (
    <div className="left_tab">
      <ul>
        <li>
          <Link
            to="/profile"
            className={pathname[1] == "profile" ? "active" : ""}
          >
            <i class="fa fa-user-o"></i>  Profile
          </Link>
        </li>
        <li>
          <Link to="/Kyc" className={pathname[1] == "Kyc" ? "active" : ""}>
          <i class="fa fa-address-card-o"></i>
          KYC
          </Link>
        </li>
        <li>
          <Link
            to="/whitelist"
            className={pathname[1] == "whitelist" ? "active" : ""}
          >
          <i class="fa fa-check-square-o"></i>

           Whitelist
          </Link>
        </li>
        {/* <li>
          <Link
            to="/Google_auth"
            className={pathname[1] == "Google_auth" ? "active" : ""}
          >
            Two-Factor Authentication
          </Link>
        </li> 
        <li>
          <Link
            to="/transactions"
            className={
              pathname[1] == "transactions" ||
              pathname[1] == "Transactions" ||
              pathname[1] == "depositeHistory" ||
              pathname[1] == "coinDeposite" ||
              pathname[1] == "coinFundRequest"
                ? "active"
                : ""
            }
          >
            Transactions History
          </Link>
        </li>
        <li>
          <Link
            to="/spot"
            className={
              pathname[1] == "spot" || pathname[1] == "spotSellHistory"
                ? "active"
                : ""
            }
          >
            Trade History
          </Link>
        </li>*/}
        <li>
          <Link to="/refer" className={pathname[1] == "refer" ? "active" : ""}>
          <i class="fa fa-share-alt"></i>
          Refer & Earn
          </Link>
        </li>
        <li>
          <Link to="/help" className={pathname[1] == "help" ? "active" : ""}>
          <i class="fa fa-headphones"></i>
          Support
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default LeftTab;
