import React, { useEffect, useState } from "react";
import DocumentMeta from "react-document-meta";
import LoginHeader from "../Common/LoginHeader";
import Footer from "../Common/Footer";
import { toast, ToastContainer } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { baseUrl } from "../Common/BaseUrl";
import axios from "axios";
// import Switch from "react-switch";
import moment from "moment";
import LeftTab from "../Common/LeftTab";
import ChangePassword from "./ChangePassword";
import Bank from "../Kyc/Bank";
const meta = {
  title: "Profile | Rubiex  Exchange",
  description:
    "Rubiex  Exchange Live Makes The Buying And Selling Of Crypto Coin Way More Effective And Profitable. We Are A Low Cost Cyrpto Exchange In India Like Bitcoin And Dogecoin.",
  canonical: "https://Rubiex  Exchange.live/buy-sell-crypto-online",
  meta: {
    charset: "utf-8",
    name: {
      keywords:
        "BTC Exchange, Crypto Trading Platform, Best Crypto Converter, Indian Crypto Exchange",
    },
  },
};

const Profile = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile_number, setMobile_number] = useState("");
  const [kycStatus, setKycStatus] = useState("N");
  const [OldPassword, setOldPassword] = useState("");
  const [OldPassError, setOldPassError] = useState("");
  const [NewPassword, setNewPassword] = useState("");
  const [NewPasswordError, setNewPasswordError] = useState("");
  const [CNewPasswordError, setCNewPasswordError] = useState("");
  const [btnDisableOP, setBtnDisableOP] = useState(false);
  const [btnDisableNP, setBtnDisableNP] = useState(false);
  const [btnDisableCNP, setBtnDisableCNP] = useState(false);
  const [image, setImage] = useState("");
  const [CPassword, setCPassword] = useState("");
  const [respStatus, setRespStatus] = useState(false);
  const [tabledata, setTabledata] = useState([]);
  const [qrcodeurl, setQrcodeurl] = useState("assets/img/qr-dummy.png");
  const [SKey, setSKey] = useState("");
  const [checkTwoFa, setCheckTwoFa] = useState("");
  const [vcode, setVcode] = useState("");
  const [TwoFaText, setTwoFaText] = useState("");
  const [message, setMessage] = useState("");
  const [isBlur, setIsBlur] = useState(true);
  const [show, setShow] = useState(false);
  const [userData, setUserData] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    getUserDetails();
    LoginActivity();
    generateGoogleCode();
  }, []);

  const getUserDetails = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/UserData", {}, config).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        setUserData(resp.data);
        setFirstName(resp.data[0].firstName);
        setLastName(resp.data[0].lastName);
        setEmail(resp.data[0].email);
        setMobile_number(resp.data[0].mobile_no);
        setKycStatus(resp.data[0].kycVerification_status);
        setImage(resp.data[0].profile_image);
        if (
          resp.data[0].enable_2fa == "N" ||
          resp.data[0].enable_2fa == "null"
        ) {
          setTwoFaText("Enable");
        }
        if (resp.data[0].enable_2fa == "Y") {
          setTwoFaText("Disable");
        }
        setCheckTwoFa(resp.data[0].enable_2fa);
      } else {
        if (
          resp.key === "logout" &&
          localStorage.getItem("token") != "null" &&
          localStorage.getItem("token") !== null
        ) {
          toast.error(resp.message);
          localStorage.clear();
          setTimeout(() => this.props.history.push("/login"), 2000);
        }
      }
    });
  };

  const generateGoogleCode = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios
      .post(baseUrl + "/frontapi/generateGoogleCode", {}, config)
      .then((resp1) => {
        var resp = resp1.data;
        setQrcodeurl(resp.data.qrCodeUrl);
        setSKey(resp.data.secretKey);
      });
  };

  const handleChange = (event) => {
    let { name } = event.target;
    if (name === "OldPassword") {
      var OldPassword = event.target.value;
      var OldPassError = "";
      if (!OldPassword) {
        toast.dismiss();
        OldPassError = "This field is required";
        setOldPassError(OldPassError);
        setBtnDisableOP(false);
        setOldPassword(OldPassword);
        return false;
      } else {
        setOldPassError("");
        setBtnDisableOP(true);
        setOldPassword(OldPassword);
      }
    }

    if (name === "NewPassword") {
      var NewPasswordd = event.target.value;
      var NewPasswordError = "";
      if (!NewPassword) {
        toast.dismiss();
        NewPasswordError = "This field is required";
        setNewPasswordError(NewPasswordError);
        setBtnDisableNP(false);
        setNewPassword(NewPasswordd);
        return false;
      } else {
        setNewPasswordError("");
        setBtnDisableNP(true);
        setNewPassword(NewPasswordd);
      }
      if (NewPassword.length < 7) {
        toast.dismiss();
        NewPasswordError =
          "Password must be at least 8 characters long, contains an upper case letter, a lower case letter, a number, and a symbol";
        setNewPasswordError(NewPasswordError);
        setBtnDisableNP(false);

        return false;
      } else {
        setNewPasswordError("");
        setBtnDisableNP(true);
        setNewPassword(NewPasswordd);
      }
      var regex = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-8])(?=.*[!@#$%^&*]).{7,}$";
      if (!NewPassword.match(regex)) {
        NewPasswordError =
          "Password must be at least 8 characters long, contains an upper case letter, a lower case letter, a number, and a symbol";
        setNewPasswordError(NewPasswordError);
        setBtnDisableNP(false);
        return false;
      } else {
        setNewPasswordError("");
        setBtnDisableNP(true);
        setNewPassword(NewPasswordd);
      }
    }

    if (name === "CPassword") {
      var CPassword = event.target.value;
      var CNewPasswordError = "";
      if (!CPassword) {
        toast.dismiss();
        CNewPasswordError = "This field is required";
        setCNewPasswordError(CNewPasswordError);
        setBtnDisableCNP(false);
        setCPassword(CPassword);
        return false;
      } else {
        setCNewPasswordError("");
        setBtnDisableCNP(true);
        setCPassword(CPassword);
      }
      if (CPassword != NewPassword) {
        toast.dismiss();
        CNewPasswordError = "Confirm password does not matched";
        setCNewPasswordError(CNewPasswordError);
        setBtnDisableCNP(false);
        setCPassword(CPassword);
        // return false;
      } else {
        setCNewPasswordError("");
        setBtnDisableCNP(true);
        setCPassword(CPassword);
      }
    }
    if (name === "vcode") {
      var vcode = event.target.value;
      setVcode(vcode);
      if (vcode.replace(/\s+/g, "").length == 0) {
        setMessage("Please enter a valid code");
        return false;
      }
      setMessage("");
    }
  };

  const verifyCode = () => {
    if (!vcode) {
      setMessage("This field is required");
      return false;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let faData = {
      code: vcode,
      TwoFaText: TwoFaText,
    };
    axios
      .post(baseUrl + "/frontapi/verifyGoogleCode", faData, config)
      .then((resp) => {
        setIsBlur(false);
        if (resp.data.status) {
          toast.success(resp.data.message);
        } else {
          toast.error(resp.data.message);
        }
        getUserDetails();
      });
  };

  const LoginActivity = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios.post(baseUrl + "/frontapi/login-list", {}, config).then((resp) => {
      var respNew = resp.data;
      if (respNew.status === true) {
        setTabledata(respNew.findData);
        setRespStatus(respNew.status);
      }
    });
  };

  const updateProfileImage = (e) => {
    e.preventDefault();
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    axios
      .post(baseUrl + "/frontapi/update-profile-image", formData, config)
      .then((resp) => {
        var response = resp.data;
        if (response.status === true) {
          toast.dismiss();
          toast.success(response.message);
          getUserDetails();
        } else {
          toast.dismiss();
          toast.error(response.message);
        }
      });
  };

  const deleteAccount = (e) => {
    e.preventDefault();
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios
      .post(baseUrl + "/frontapi/delete-account", {}, config)
      .then((resp) => {
        var response = resp.data;
        if (response.status === true) {
          toast.dismiss();
          toast.success(response.message);
          localStorage.removeItem("token");
          window.location.href = "/";
        } else {
          toast.dismiss();
          toast.error(response.message);
        }
      });
  };

  const maxCharacters = 19;
  const truncatedEmail =
    email && email.length > maxCharacters
      ? email.slice(0, maxCharacters) + "..."
      : email;
  return (
    <div>
      <DocumentMeta {...meta}>
        <LoginHeader />
        <div className="bghwhite">
          <div className="new_inpage">
            <div className="container-fluid continer2">
            <div className="row">
            <div className="col-md-3 pr-lg-0">
              <LeftTab />
              </div>
              <div className="google-auth col-md-9 "> 
                <h3 className="mb-3"><b>My Profile</b></h3>
                  <div className="auth-left   mb-3">
                    <div className="mb-4 d-flex "> 
                      <div className="user_profile">
                        {image == "" || image == undefined || image == null ? (
                          <img
                            src="/assets/img/user_profile.png"
                            alt=""
                            className="img-fluid"
                          />
                        ) : (
                          <img
                            src={baseUrl + "/static/profile/" + image}
                            alt=""
                            className="img-fluid"
                          />
                        )}
                        <div className="edit-profile position-absolute">
                          <input
                            type="file"
                            // ref={myref}
                            id="img1"
                            style={{ display: "none" }}
                            onChange={updateProfileImage}
                          />
                          <label htmlFor="img1" className="editlabel">
                            <i className="fa fa-edit"></i>
                          </label>
                        </div>
                      </div>
                      <div className="pl-md-5 pl-3"> 
                      <h5 className="user text-center">
                        {firstName} {lastName == "null" ? "" : lastName}
                      </h5>

                      <div className="profile-info info-main-outer ">
                        <ul>
                          <li className="user row mb-2">
                            
                            <span className="col-auto  " title={email}>
                              {truncatedEmail}
                            </span>
                          </li>
                          <li className="user row mb-2">
                           
                            <span className="col-auto  ">
                              {mobile_number}
                            </span>
                          </li>
                          <li className="user row mb-2">
                            <span className="col-auto">KYC:  {kycStatus === "Y" ? "Completed" : "Pending"}</span>
                           
                          </li>
                          <li className="user row mb-2">
                            <span className="col-auto">Delete Account:</span>
                            <span className="col-auto ">
                              <button
                                className="btn btn-danger"
                                onClick={handleShow}
                              >
                                Delete
                              </button>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div> 
                    </div> 
                  <ChangePassword />

                  <div className="auth-left  mb-3">
                    <div className="white_box  ">
                      <h5>Google Authentication</h5>
                      <div className="google-auth d-md-flex">
                        <div className="auth-left mb-3">
                          <div
                            className={
                              isBlur == true
                                ? "qr-outer kyc_bg qr_load"
                                : "qr-outer kyc_bg"
                            }
                          >
                            <img
                              className="img-fluid"
                              src={qrcodeurl}
                              alt="qrCode"
                            />
                          </div>
                        </div>

                        <div className="auth-right pl-md-4">
                          <h5 className="kyc_h5">
                            Key :<span> {SKey}</span>{" "}
                          </h5>

                          <h5>
                            Status: {checkTwoFa == "Y" ? "Enabled" : "Disabled"}
                          </h5>
                          <label className="fw-500">Authentication Code</label>
                          <div className="row">
                            <div className=" col-7 pr-0">
                              <input
                                type="text"
                                className="form-control"
                                name="vcode"
                                placeholder="Enter Code"
                                value={vcode}
                                onChange={handleChange}
                              />
                            </div>
                            <div className=" col-5">
                              <button
                                onClick={verifyCode}
                                className="btn btn_man w100px"
                              >
                                {TwoFaText}
                              </button>
                            </div>
                          </div>

                          <p className="mt-0" style={{ color: "red" }}>
                            {message}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <Bank userinfo={userData} />
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Account</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Woohoo, Are you sure you want to delete your account?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="danger" onClick={deleteAccount}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
        <ToastContainer />
        <Footer />
      </DocumentMeta>
    </div>
  );
};

export default Profile;
