import React, { useState, useEffect } from "react";
import LoginHeader from "./Common/LoginHeader";
import Footer from "./Common/Footer";
import { Link } from "react-router-dom";
import DocumentMeta from "react-document-meta";
import * as myConstList from "./Common/BaseUrl";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "moment";
import LeftTab from "./Common/LeftTab";
import { ProductName } from "./Common/ProductName";

const baseUrl = myConstList.baseUrl;

function Help() {
  const [isInputShow, setIsInputShow] = useState(false);
  const [desc, setDesc] = useState("");
  const [userUploadImage, setUserUploadImage] = useState("");
  const [btnnDisable, setBtnnDisable] = useState(true);
  const [issueType, setIssueType] = useState("");
  const [issue, setIssue] = useState("");
  const [issueTypeError, setIssueTypeError] = useState("");
  const [issueError, setIssueError] = useState("");
  const [issueImage, setIssueImage] = useState("");
  const [respStatus, setRespStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [tabledata, setTabledata] = useState([]);
  useEffect(() => {
    TicketList();
  }, []);

  const TicketList = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios
      .post(baseUrl + "/frontapi/helplist-by-user", {}, config)
      .then((resp) => {
        const respNew = resp.data;

        if (respNew.status === true) {
          setTabledata(respNew.data);
          setRespStatus(respNew.status);
        }
      });
  };

  const helpsbmt = (event) => {
    event.preventDefault();

    if (issueType === "") {
      setIssueError("Please select the subject");
      return false;
    }

    const formData = new FormData();
    formData.append("userId", localStorage.getItem("token"));
    if (issueType === "others") {
      formData.append("issue", issue);
    } else {
      formData.append("issue", issueType);
    }
    if (issueImage) {
      var fileSize = issueImage.size / 1024;
      if (fileSize > 1024) {
        setMessage("Maximum 1 MB Image allowed.");
        return false;
      }
    }
    formData.append("issueType", desc);
    formData.append("issueImage", issueImage);

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "multipart/form-data",
      },
    };
    // Validations //
    if (issueType === "others") {
      if (!issue) {
        setIssueError("This field is required");
        return false;
      }
    }
    if (!desc) {
      setIssueTypeError("This field is required");
      return false;
    }
    // V end //
    setBtnnDisable(true);
    setTimeout(() => setBtnnDisable(false), 2000);
    axios.post(baseUrl + "/frontapi/help", formData, config).then((resp) => {
      const respData = resp.data;
      if (respData.status === true) {
        toast.dismiss();
        toast.success(respData.message);
        TicketList();
      } else {
        toast.dismiss();
        toast.error(respData.message);
      }
      setRespStatus(respData.status);
    });
  };

  const onChangeImage = (e) => {
    e.preventDefault();

    setIssueImage("");
    setUserUploadImage(false);

    const issueImage = e.target.files[0];
    var fileSize = e.target.files[0].size / 1024;
    setIssueImage(e.target.files[0]);
    setUserUploadImage(URL.createObjectURL(issueImage));

    if (issueImage === false) {
      setMessage("Please select an image.");
      setRespStatus(false);
      return false;
    }
    if (fileSize > 1024) {
      setMessage("Maximum 1 MB Image allowed.");
      return false;
    }
    if (!issueImage.name.match(/\.(jpg|jpeg|png)$/)) {
      setMessage("Please select a valid image (jpg|jpeg|png).");
      setRespStatus(false);
      return false;
    }
    setMessage("");
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setIssueType(value);

    if (value === "") {
      setIssueError("Please select the subject");
      return false;
    }

    if (value === "others") {
      setIsInputShow(true);
      setBtnnDisable(false);
    } else {
      setIsInputShow(false);
      setBtnnDisable(false);
    }
    setIssueError("");
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    if (name === "issue") {
      const issue = value;
      let IssueError = "";
      if (!issue) {
        IssueError = "This field is required";
      } else if (value.replace(/\s+/g, "").length === 0) {
        IssueError = "Please enter a valid subject field";
      } else if (issue.length > 50) {
        IssueError = "Max character limit is 50";
      }
      setIssueError(IssueError);
    }
    setIssue(value);
  };

  const showTableHtml = () => {
    if (respStatus === true) {
      const html = [];
      tabledata.map((value, i) => {
        const U_ID = value.id;
        const encodeMe = btoa(U_ID);

        const UserLink1 = `/Chatting/${encodeMe}/${value.seenStatus}`;

        html.push(
          <tr>
            <td>{value.id}</td>
            <td className="capital">{value.issue}</td>
            <td>
              {value.status == 0 && "Pending"}
              {value.status == 1 && "Resolved"}
            </td>
            <td>{Moment(value.createAt).format("lll")}</td>
            {value.updateAt ? (
              <td>{Moment(value.updateAt).format("lll")}</td>
            ) : (
              <td></td>
            )}
            <td
              className={
                value.status == 0 && value.seenStatus == "1"
                  ? "position-relative chat-count"
                  : ""
              }
            >
              <Link className="btn btn_man position-relative" to={UserLink1}>
                {" "}
                {value.status == 0 ? "Chat" : "View"}{" "}
                {/* <span
                  className="chat_notification"
                  style={{
                    display: value.seenStatus == "1" ? "block" : "none",
                  }}
                >
                  {value.seenStatus == "1" ? "+1" : ""}
                </span> */}
              </Link>
            </td>
          </tr>
        );
      });

      return html;
    }
  };

  const meta = {
    title: `Support | ${ProductName} Exchange`,
    description: `Login on ${ProductName} Exchange now and start trading! Invite your friends to ${ProductName} Exchange.`,
    meta: {
      charset: "utf-8",
      name: {
        keywords: `${ProductName} Exchange , Crypto Exchange, Buy Sell Crypto, Bitcoin Exchange India`,
      },
    },
  };

  return (
    <DocumentMeta {...meta}>
      <div>
        <LoginHeader />
        <div className="bghwhite">
          <div className="new_inpage">
            <div className="container-fluid">
            <div className="row">
            <div className="col-md-3 pr-lg-0">
              <LeftTab />
              </div>
              <div className="col-md-9"> 
              <h4 className="mb-3">Support</h4>
       
                  <div className="white_box ">
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label className="fw-500">Subject</label>
                        <select
                          className="form-control"
                          onChange={handleChange}
                          value={issueType}
                          name="issueType"
                          id="issueType"
                        >
                          <option value="">Select Subject</option>
                          <option value="exchange">Exchange</option>
                          <option value="login">Login</option>
                          <option value="signup">Signup</option>
                          <option value="kyc">KYC</option>
                          <option value="account">Account</option>
                          <option value="others">Others</option>
                        </select>
                      </div>
                      <div className="form-group col-md-6">
                        {isInputShow === true && (
                          <>
                            <label className="fw-500">Other Subject</label>
                            <input
                              className="form-control"
                              name="issue"
                              id="issue"
                              placeholder="Enter Issue"
                              onChange={handleChangeInput}
                              value={issue}
                            />
                          </>
                        )}
                        <span style={{ color: "red" }}>{issueError}</span>
                      </div>

                      <div className="form-group col-md-6">
                        <label className="fw-500"> Description </label>

                        <textarea
                          type="text"
                          value={desc}
                          onChange={(event) => {
                            setDesc(event.target.value);
                            if (!event.target.value) {
                              setIssueTypeError("This field is required");
                            } else {
                              setIssueTypeError("");
                            }
                          }}
                          className="form-control form_control_textarea"
                          placeholder="Enter Issue Type"
                          id="IssueType"
                        />
                        <span style={{ color: "red" }}>{issueTypeError}</span>
                      </div>

                      <div className="form-group mb-0 col-md-6">
                        <label className="fw-500">Attachments</label>

                        <div className="add_fileinput">
                          <span>Add File</span>
                          <input
                            className="profile_input"
                            type="file"
                            name="issueImage"
                            onChange={onChangeImage}
                            accept=".jpg, .jpeg, .png"
                          />
                        </div>
                        <label className="mb-0">
                          <img
                            src={userUploadImage}
                            cl
                            className="img-fluid22 support-img"
                            alt=""
                          />
                        </label>
                      </div>
                      <span style={{ color: "red" }}>{message}</span>
                      </div>
                      <div className="form-group">
                        <input
                          onClick={helpsbmt}
                          type="submit"
                          value="Submit"
                          className="btn w100px btn_man "
                          disabled={btnnDisable}
                        />
                      </div>
                    </div>
                
                
                  <div className="white_box  ">
                    <h6>Ticket History</h6>
                    <div className="table-responsive ticket-history-outer">
                      <div className="scrool_wallet_box">
                        <table className="table  mb-0 fs14">
                          <thead>
                            <tr>
                              <th>Ticket Id</th>
                              <th>Subject</th>
                              <th>Status</th>
                              <th>Created At</th>
                              <th>Last Updated</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>{showTableHtml()}</tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                
              </div>
            </div>  </div>
          </div> 
        </div>

        <Footer />
        <ToastContainer limit={1} />
      </div>
    </DocumentMeta>
  );
}

export default Help;
